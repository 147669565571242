<template>
  <div class="newsiteName">
    <div class="top">新建站点</div>
    <div class="content">
      <van-field clickable 
                  readonly
                  v-model="stationDept"
                  label="所属运营部"
                  placeholder="请选择"
                  @click="stationDeptShow = true"
                  right-icon="arrow-down"> </van-field>
      <van-field clickable 
                  readonly
                  v-if="!isFirstShowLong"
                  v-model="addressVal"
                  label="省市区县"
                  placeholder="省份、城市、区县"
                  @click="addressShow = true"
                  right-icon="arrow-down"> </van-field>
      <van-field clickable
                  readonly
                  autosize
                  v-if="isFirstShowLong"
                  input-align="right"
                  type="textarea"
                  v-model="addressVal"
                  label="省市区县"
                  placeholder="省份、城市、区县"
                  @click="addressShow = true"
                  right-icon="arrow-down"> </van-field>
      <van-field clickable
                  readonly
                  v-if="!isSecondShowLong"
                  v-model="pageInfoShowVal.countryVal"
                  label="乡镇村"
                  placeholder="乡镇村"
                  @click="countryClick"
                  right-icon="arrow-down"> </van-field>
      <van-field clickable
                  readonly
                  v-if="isSecondShowLong"
                  autosize
                  input-align="right"
                  type="textarea"
                  v-model="pageInfoShowVal.countryVal"
                  label="乡镇村"
                  placeholder="乡镇村"
                  @click="countryClick"
                  right-icon="arrow-down"> </van-field>
      <van-field readonly
                  v-model="pageInfo.stationName"
                  label="站点名称"
                  placeholder="村名+服务站"> </van-field>
       <van-field v-model="pageInfoShowVal.gps" clickable readonly @click="gpsClick" label="站点GPS位置" right-icon="location-o"> </van-field>
       <van-field class="otherInfo" v-model="pageInfoShowVal.otherInfo" type="textarea" placeholder="乡村信息有系统自动带出，不需要编辑" :autosize=true readonly> </van-field>
    </div>

    <div class="nextBtn flex" >
      <van-button class="publicBtnActive left-btn"
                  @click="goback">取消</van-button>
      <van-button class="publicBtnActive"
                  @click="nextClick">下一步</van-button>
    </div>

    <van-popup v-model="addressShow"
               position="bottom">
      <van-area :area-list="areaList"
                @cancel="addressShow = false"
                @confirm="areaConfirm"> </van-area>
    </van-popup>

    <van-popup v-model="countryShow"
               position="bottom">
      <van-picker show-toolbar
                  title="乡镇村"
                  :columns="countryList"
                  @cancel="countryShow = false"
                  @confirm="countryConfirm"
                  visible-item-count='5' />
    </van-popup>
    <van-popup v-model="mapshow" v-if="mapshow" position="top">
      <div class="mapDiv">
        <div class="close">
          <i @click="closeMapClick">
            <van-icon name="cross" />
          </i>
        </div>
        <baidu-map style="width:100%;height: 90%"
                   :center="mapCenter" :zoom="mapZoom"
                   :scroll-wheel-zoom="true"
                   @ready="onBaiduMapReady"
                   @click="getClickInfo"
                   @moving="syncCenterAndZoom"
                   @moveend="syncCenterAndZoom"
                   @zoomend="syncCenterAndZoom"
        >
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"> </bm-navigation>
          <bm-marker :position="mapCenter" :dragging="true"> </bm-marker>
          <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"> </bm-geolocation>
        </baidu-map>

<!--        <el-amap ref="map" vid="amapDemo" :amap-manager="amapManager" :center="center" :zoom="zoom" :plugin="plugin" :events="events" class="amap-demo">-->
<!--        </el-amap>-->
      </div>
    </van-popup>

    <van-popup v-model="stationDeptShow"
               position="bottom">
      <van-picker show-toolbar
                  title="所属运营部"
                  :columns="deptList"
                  @cancel="stationDeptShow = false"
                  @confirm="stationDepConfirm"
                  visible-item-count='5' />
    </van-popup>

  </div>
</template>

<script>
import common from '@/utils/common'
import { AMapManager,lazyAMapApiLoaderInstance} from 'vue-amap';
import areaList from "@/utils/area"
let amapManager = new AMapManager()
import { getAreaInfoCountyDict, getVillageInfoByAreaCode, getStationInfoByCode, saveStationName, getDeptList } from '@/utils/api'
import { appAction, uwStatisticAction} from "@/utils/util";
import { PREVIOUS_PAGE, Home_Page } from "@/utils/constantNum";
export default {
  data() {
    let self = this
    return {
      isFirstShowLong: false,
      isSecondShowLong: false,
      addressVal: null,
      areaList,
      pageInfoShowVal: {
        countryVal:null,
        gps: null,
        otherInfo: null,
      },
      pageInfo: {
        locateValue: null,
        locateKey: null,
        villageCode: null,
        stationName: null,
        lng: null,
        lat: null,
      },
      addressShow: false,
      countryShow: false,
      mapshow: false,
      amapManager,
      zoom: 12,
      center: [],
      countryList:[],
      countryListAll:[],
      isSiteExist: false,
      events: {
        init: (o) => {
          o.getCity(result => {
            console.log(result)
          })
        },
        'moveend': () => {
        },
        'zoomchange': () => {
        },
        'click': (e) => {
          console.log('map clicked',e);
          let { lng, lat } = e.lnglat
          self.pageInfo.lat = lat
          self.pageInfo.lng = lng
          console.log("self.pageInfo",self.pageInfo)
          self.center = [lng, lat]

          let geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: 'all'
          })
          geocoder.getAddress([lng, lat], function (status, result) {
            console.log("地址：",result)
            if (status === 'complete' && result.info === 'OK') {
              if (result && result.regeocode) {
                console.log(result.regeocode.formattedAddress)
                self.pageInfoShowVal.gps = result.regeocode.formattedAddress

                self.mapshow = false
                self.$nextTick()
              }
            }
          })
        }
      },
      plugin: ['ToolBar', {
        pName: 'MapType',
        defaultType: 0,
        events: {
          init(o) {console.log(o);}
        }
      }],
      deptList:[],
      deptAllList:[],
      stationDept: '',
      stationDeptval: '',
      stationDeptShow: false,
      mapCenter:{},
      mapZoom:20,
      BMap: null, // 地图组件是否就绪

    }
  },
  mounted() {
    uwStatisticAction('/newsiteName', '新建站点名字')
    this.togetDeptList()
  },
  created() {

  },
  methods:{
    syncCenterAndZoom(e) {
      // 返回地图当前的缩放级别
      this.mapZoom = e.target.getZoom()
    },
    //地图初始化
    onBaiduMapReady(e) {
      const that = this
      that.BMap = e.BMap
        if (that.BMap) {
          // 获取定位地址信息并赋值给声明变量
          // Geolocation 对象的 getCurrentPosition()、watchPosition()、clearWatch()
          const geolocation = new that.BMap.Geolocation()
          geolocation.enableSDKLocation();
          // 通过 getCurrentPosition() 方法：获取当前的位置信息
          geolocation.getCurrentPosition(res => {
            if(!that.mapCenter.lng && !that.mapCenter.lat){
              that.mapCenter = { lng:res.point.lng, lat:res.point.lat}
            }
          })
      }
    },
    //地图点击事件
    getClickInfo(e) {
      let that = this
      // 调整地图中心位置
      that.mapCenter.lng = e.point.lng
      that.mapCenter.lat = e.point.lat
      console.log('点击的地址', e, that.mapCenter)
      // 此时已经可以获取到BMap类
      if (that.BMap) {
        // Geocoder() 类进行地址解析
        // 创建地址解析器的实例
        const geoCoder = new this.BMap.Geocoder()
        // getLocation() 类--利用坐标获取地址的详细信息
        // getPoint() 类--获取位置对应的坐标
        geoCoder.getLocation(e.point, function(res) {
          console.log('获取经纬度', e.point, '获取详细地址', res)
          const addrComponent = res.addressComponents
          const surroundingPois = res.surroundingPois
          let addr = addrComponent.street
          if (surroundingPois.length > 0 && surroundingPois[0].title) {
            if (addr) {
              addr += `-${surroundingPois[0].title}`
            } else {
              addr += `${surroundingPois[0].title}`
            }
          } else {
            addr += addrComponent.streetNumber
          }
          that.pageInfoShowVal.gps = addr;
          that.mapshow = false;
          // const province = addrComponent.province
          // const city = addrComponent.city
          // const district = addrComponent.district
          // that.choosedLocation = { province, city, district, addr, ...that.center }
        })
      }
    },
    stationDepConfirm(val,i) {
      this.stationDept = this.deptAllList[i].deptName
      this.stationDeptval = this.deptAllList[i].deptNo
      this.stationDeptShow = false
    },
    togetDeptList() {
      this.deptList = []
      getDeptList({ token:common.getItem('wtToken') }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.deptAllList = res.data.data
            for(let obj of this.deptAllList){
              this.deptList.push(obj.deptName)
            }
          }else{
            this.$toast(res.data.businessMessage)
          }
        }
      })
    },
    nextClick() {
      if (!this.stationDept) {
        this.$toast("请选择所属运营部")
        return
      }
      if (this.isSiteExist) {
        this.$toast("站点已存在")
        return
      }
      if (!this.addressVal) {
        this.$toast("请选择省市区县")
        return
      }
      if (!this.pageInfoShowVal.countryVal) {
        this.$toast("请选择省乡镇村")
        return
      }
      if (!this.pageInfoShowVal.gps) {
        this.$toast("请选择站点GPS位置")
        return
      }
      saveStationName({
        stationDept: this.stationDeptval,
        stationName: this.pageInfo.stationName,
        villageCode: this.pageInfo.villageCode,
        lng: this.pageInfo.lng,
        lat: this.pageInfo.lat,
        locateValue: JSON.stringify(this.pageInfo.locateValue),
        locateKey: JSON.stringify(this.pageInfo.locateKey),
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200){
            this.$router.push({name: 'newsiteList', query:{stationNo: res.data.data.stationNo}})
          }else{
            this.$toast(res.data.businessMessage)
          }
        }
      })
      
    },
    goback(){
      appAction(PREVIOUS_PAGE)
      this.$router.push({name: 'mysite', query:{pageType: Home_Page, role: common.getItem('userRole')}})
    },
    countryClick () {
      if (!this.addressVal) {
        this.$toast('请先选择省市区县')
        return
      }
      this.countryShow = true
    },
    gpsClick () {
      if (!this.pageInfoShowVal.countryVal) {
        this.$toast('请先选择乡镇村')
        return
      }
      this.mapshow = true
    },
    closeMapClick(){
      this.mapshow = false
    },
    areaConfirm (val) {
      this.countryListAll = []
      this.pageInfoShowVal.countryVal = ''
      this.pageInfo.stationName = ''
      let code = ''
      this.addressVal = ''
      this.addressShow = false
      this.pageInfoShowVal.gps = ''
      for (let obj of val) {
        this.addressVal += obj.name + ' '
        code += obj.name
        this.isFirstShowLong = this.addressVal.length > 16
      }
      this.areaCode = val[2].code
      this.pageInfo.locateValue = {
        province: val[0].name,
        city: val[1].name,
        county: val[2].name
      }
      this.pageInfo.locateKey = {
        province: val[0].code,
        city: val[1].code,
        county: val[2].code
      }
      this.getAreaInfoCounty(this.areaCode)
    },
    countryConfirm (val, i) {
      this.countryShow = false
      this.pageInfoShowVal.countryVal = val.toString()
      this.isSecondShowLong = this.pageInfoShowVal.countryVal.length > 13
      this.pageInfo.stationName = val[1] + '服务站'
      if (this.pageInfo.stationName.indexOf("村委会") > 0) {
        this.pageInfo.stationName = this.pageInfo.stationName.replace("村委会", "")
      }
      if (this.pageInfo.stationName.indexOf("居委会") > 0) {
        this.pageInfo.stationName = this.pageInfo.stationName.replace("居委会", "");
      }

      if (this.pageInfo.stationName.indexOf("村") > 0 && this.pageInfo.stationName.length > 5) {
        this.pageInfo.stationName = this.pageInfo.stationName.replace("村", "")
      }

      let self = this
      self.pageInfo.lat = this.countryList[i[0]].county[i[1]].lat
      self.pageInfo.lng = this.countryList[i[0]].county[i[1]].lng
      if (self.pageInfo.lat == undefined || self.pageInfo.lng == undefined) {
        self.pageInfo.lat = 39.924074
        self.pageInfo.lng = 116.403419
      }

      // self.center = [self.pageInfo.lng,self.pageInfo.lat]
      // let geocoder = new AMap.Geocoder({
      //   radius: 1000,
      //   extensions: 'all'
      // })
      // geocoder.getAddress(self.center, function (status, result) {
      //   if (status === 'complete' && result.info === 'OK') {
      //     if (result && result.regeocode) {
      //       console.log(result.regeocode.formattedAddress)
      //       self.pageInfoShowVal.gps = result.regeocode.formattedAddress
      //
      //       self.$nextTick()
      //     }
      //   }
      // })

      this.pageInfo.locateValue.town = this.countryList[i[0]].name
      this.pageInfo.locateValue.village = this.countryList[i[0]].county[i[1]].name
      this.pageInfo.locateKey.town = this.countryList[i[0]].areaCode
      this.pageInfo.locateKey.village = this.countryList[i[0]].county[i[1]].areaCode
      this.pageInfo.villageCode = this.countryList[i[0]].county[i[1]].areaCode

      let para = {
        villageCode: this.pageInfo.villageCode,
        stationDept:this.stationDeptval
      }
      getStationInfoByCode(para).then(res => {
        this.isSiteExist = false
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            let json = data.data
            if (!json) {
              return
            }
            this.pageInfo.placeNature = json.placeNature ? json.placeNature : ''
            for (let item of this.sitePropApiList) {
              if (item.dictKey === this.pageInfo.placeNature) {
                this.pageInfoShowVal.placeNature = item.dictValue
              }
            }
            this.pageInfo.recommendSource = json.recommendSource ? json.recommendSource: ''
            for (let item of this.siteSourceApiList) {
              if (item.dictKey === this.pageInfo.recommendSource) {
                this.pageInfoShowVal.recommendSource = item.dictValue
              }
            }

          } else if (data.businessCode == 30017) {
            // 站点存在
            this.isSiteExist = true
          }
        }
      })
      getVillageInfoByAreaCode(this.pageInfo.villageCode).then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            if (data.villageInfo) {
              self.pageInfoShowVal.otherInfo = data.villageInfo
            } else {
              self.pageInfoShowVal.otherInfo = ''
            }
            if (data.villageInfo) {
              self.pageInfo.households = data.households
            }
            if (data.villageInfo) {
              self.pageInfo.peopleNum = data.peopleNum
            }
          }
        }
      })
    },
    getAreaInfoCounty (countyNo) {
      this.countryList = []
      getAreaInfoCountyDict(countyNo).then((res) => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          this.countryListAll = data.townList
          for (let obj of this.countryListAll) {
            obj.children = obj.county
            for (let o of obj.children) {
              o.text = o.name
            }
            obj.text = obj.name
            this.countryList.push(obj)
          }
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.newsiteName{
  min-height: 100%;
  background: #F7F8F9;
  position: relative;
  padding-bottom: 2rem;
  .top{
    height: 2.5rem;
    background: linear-gradient(152deg, #30BF78 0%, #0A6227 100%);
    opacity: 0.7;
    color: #fff;
    font-size: 0.46rem;
    padding: .3rem;
  }
  .content{
    width: 6.9rem;
    min-height: 9.38rem;
    background: #fff;
    margin: -1.2rem 0.3rem 0; 
    z-index: 10;
    border-radius: 0.16rem;
    padding-bottom: 0.3rem;
    .van-cell{
      padding: 0.28rem 0.2rem;
    }
    .otherInfo{
      background: #F7F8F9;
      min-height: 4.5rem;
      width: 6.3rem;
      margin: 0 auto;
    }
  }
  .mapDiv{
    background:rgba(0,0,0,0.3);
    padding: 10px;
    width: 375px;
    // height: 667px;
    height: 100vh;
    .el-vue-amap-container, .el-vue-amap-container .el-vue-amap{
      height: calc(100vh - 2rem);
    }
  }
  .close {
    text-align: right;
    margin-bottom: 14px;
    .van-icon {
      background: url("../../assets/img/close.png");
      background-size: 100% 100%;
      width: 24px;
      height: 24px;
    }
  }
  .nextBtn {
    width: 100%;
    padding: 12px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
    .left-btn{
      background: #fff;
      color:#333;
      border: 1px solid #E5E5E5;
      margin-right: 0.24rem;
    }
  }
}
</style>

